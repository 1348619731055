import {
  membersAppDefId,
  myWishlistInstallDefinition,
  ecomAppDefID,
  addressesAppDefId,
  myAdressesInstallDefinition,
  myWalletAppDefId,
  myWalletInstallDefinition,
  orderHistoryPageId,
  wishlistPageId,
  myOrdersInstallDefinition,
  wishlistAppDefId,
  membersPagesContentKeys,
  membersPagesScreenshots,
  membersPagesFullDescriptionKeys,
  lightboxes,
} from '../constants';
import {Logger} from '@wix/bi-logger-ec-sf';
import _ from 'lodash';
import {FetchError} from '@wix/wixstores-client-core/dist/src/http/FetchError';
import {BulkInstallResponse} from '../types';

export class DependantApps {
  constructor(
    private readonly sdk: IEditorSdk,
    private readonly appToken: string,
    private readonly biLogger: Logger,
    private readonly options,
    private readonly instance: string
  ) {
    //
  }

  public installApps(): Promise<void> {
    // eslint-disable-next-line  @typescript-eslint/no-misused-promises
    return new Promise(async (resolve, reject) => {
      if (this.options && this.options.firstInstall) {
        if (this.options.origin && this.options.origin.type === 'ADI') {
          return resolve();
        } else {
          return this.installMembers().then(resolve, reject);
        }
      } else {
        const membersInstalled = await this.isMembersInstalled();
        if (membersInstalled) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.biLogger.exposureEventForTests({
            type: 'members_is_installed',
            testName: 'express_checkout',
            is_eligible: true,
          });
        } else {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.biLogger.exposureEventForTests({
            testName: 'express_checkout',
            is_eligible: false,
          });
        }

        if (membersInstalled) {
          await this.installMembersAreaPages({shouldInstallMyOrders: false});
        }
        resolve();
      }
    });
  }

  public onMembersInstall(shouldUseNewMembersApi): Promise<any> {
    if (this.options.firstInstall) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.biLogger.exposureEventForTests({
        type: 'members_added_by_stores',
        testName: 'express_checkout',
        is_eligible: true,
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.biLogger.exposureEventForTests({
        type: 'members_added_by_user',
        testName: 'express_checkout',
        is_eligible: true,
      });
    }

    return Promise.all([
      shouldUseNewMembersApi ? Promise.resolve() : this.installMembersAreaPages(),
      this.addCheckout(),
    ]);
  }

  private getMembersApi() {
    return this.sdk.application.getPublicAPI(this.appToken, {appDefinitionId: membersAppDefId});
  }

  public async isAppInstalled(appDefinitionId: string): Promise<boolean> {
    return this.sdk.tpa.isApplicationInstalled(this.appToken, {
      appDefinitionId,
    });
  }

  public async isMembersInstalled(): Promise<boolean> {
    return this.sdk.tpa.isAppSectionInstalled(this.appToken, {sectionId: orderHistoryPageId});
  }

  public addCheckout() {
    return this.sdk.tpa.isAppSectionInstalled(this.appToken, {sectionId: 'checkout'}).then((isInstalled) => {
      if (!isInstalled) {
        return this.sdk.tpa.add.component(this.appToken, {
          appDefinitionId: ecomAppDefID,
          componentType: 'PAGE',
          page: {
            pageId: 'checkout',
            shouldNavigate: false,
          },
        });
      }
    });
  }

  public installMembers(): Promise<void> {
    return this.sdk.application.install(this.appToken, {
      appDefinitionId: membersAppDefId,
      initiatorAppDefinitionId: ecomAppDefID,
    });
  }

  public installWishlistTPA(): Promise<void> {
    return fetch('/_api/site-apps/v1/site-apps/bulk-install', {
      method: 'post',
      body: JSON.stringify({
        apps: [{id: wishlistAppDefId}],
      }),
      headers: {
        Authorization: this.instance,
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new FetchError('bulk install failed', {response});
        }
        return response.json();
      })
      .then((data: BulkInstallResponse) => {
        if (!data.installedApps[wishlistAppDefId].instanceId) {
          throw new Error('bulk install failed - no instanceId was returned');
        }
      });
  }

  public async tryInstallWishlist(translations, assetsUrl): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.biLogger.wishlistInstallationStage({stage: 'tryInstallWishlist-init'});
    const installWishlistTpaPromise = this.installWishlistTPA();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.biLogger.wishlistInstallationStage({stage: 'tryInstallWishlist-after-create-installWishlistTPAPromise'});
    const installWishlistSectionInMembersPromise = this.getMembersApi().addApplications([
      this.getMembersAreaPageWithTranslatedContent(myWishlistInstallDefinition, translations, assetsUrl),
    ]);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.biLogger.wishlistInstallationStage({
      stage: 'tryInstallWishlist-after-create-installWishlistSectionInMembersPromise',
    });
    await Promise.all([installWishlistSectionInMembersPromise, installWishlistTpaPromise]);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.biLogger.wishlistInstallationStage({stage: 'tryInstallWishlist-after-promiseAll'});
  }

  public async installWishlistPageInMembersArea(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.biLogger.wishlistInstallationStage({stage: 'installWishlistPageInMembersArea-init'});
    const [isMembersInstalled, isMyWishlistInstalled] = await Promise.all([
      this.isMembersInstalled(),
      this.sdk.tpa.isAppSectionInstalled(this.appToken, {
        sectionId: wishlistPageId,
      }),
    ]);
    if (isMembersInstalled && !isMyWishlistInstalled) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.biLogger.wishlistInstallationStage({stage: 'installWishlistPageInMembersArea-inside-if'});
      const installWishlistTpaPromise = this.installWishlistTPA();

      const installWishlistSectionInMembersPromise = this.getMembersApi().addApplications([
        myWishlistInstallDefinition,
      ]);
      await Promise.all([installWishlistSectionInMembersPromise, installWishlistTpaPromise]);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.biLogger.wishlistInstallationStage({stage: 'installWishlistPageInMembersArea-after-promise-all'});
      await this.sdk.document.save().catch(() => {
        //
      });
    }
  }

  public async uninstallWishlistPageInMembersArea(): Promise<void> {
    const [isMembersInstalled, isMyWishlistInstalled] = await Promise.all([
      this.isMembersInstalled(),
      this.sdk.tpa.isAppSectionInstalled(this.appToken, {
        sectionId: wishlistPageId,
      }),
    ]);
    if (isMembersInstalled && isMyWishlistInstalled) {
      await this.getMembersApi().removeMembersAreaPage(myWishlistInstallDefinition.pageId);
      await this.sdk.document.save().catch(() => {
        //
      });
    }
  }

  private async installMembersAreaPages({shouldInstallMyOrders = true} = {}) {
    const [isMyAdressesInstalled, isMyWalletInstalled] = await Promise.all([
      this.sdk.tpa.isApplicationInstalled(this.appToken, {appDefinitionId: addressesAppDefId}),
      this.sdk.tpa.isApplicationInstalled(this.appToken, {appDefinitionId: myWalletAppDefId}),
    ]);

    const shouldInstallAdresses = !isMyAdressesInstalled;
    const shouldInstallMyWallet = !isMyWalletInstalled;

    const appsToInstall = [
      shouldInstallMyOrders && myOrdersInstallDefinition,
      shouldInstallAdresses && myAdressesInstallDefinition,
      shouldInstallMyWallet && myWalletInstallDefinition,
    ].filter((app) => !!app);

    if (appsToInstall.length === 0) {
      return;
    }

    await this.getMembersApi().addApplications(appsToInstall);
    await this.sdk.document.save().catch(() => {
      //
    });
  }

  private getMembersAreaPageWithTranslatedContent(appDef, translations, assetsUrl) {
    const content = {page: _.mapValues(membersPagesContentKeys[appDef.pageId], translations)};
    const descriptionBullets = membersPagesFullDescriptionKeys[appDef.pageId].bullets
      .map((key) => `<li>${translations(key)}</li>`)
      .join('');
    content.page.description = `<div><span>${translations(
      membersPagesFullDescriptionKeys[appDef.pageId].title
    )}</span><ul>${descriptionBullets}</ul></div>`;
    content.page.iconURL = `${assetsUrl}/images/wixstores-54px.png`;
    content.page.screenshots = membersPagesScreenshots[appDef.pageId].map(
      (fileName) => `${assetsUrl}/images/${fileName}`
    )[0];
    return {...appDef, ...content};
  }

  private getMembersAreaPageForFutureInstallation(appDef) {
    return {...appDef, shouldInstallInitially: false};
  }

  public getMembersAreaAppsToInstall(translations, assetsUrl): any[] {
    return [
      this.getMembersAreaPageWithTranslatedContent(myOrdersInstallDefinition, translations, assetsUrl),
      this.getMembersAreaPageWithTranslatedContent(myAdressesInstallDefinition, translations, assetsUrl),
      this.getMembersAreaPageWithTranslatedContent(myWalletInstallDefinition, translations, assetsUrl),
      this.getMembersAreaPageWithTranslatedContent(
        this.getMembersAreaPageForFutureInstallation(myWishlistInstallDefinition),
        translations,
        assetsUrl
      ),
    ];
  }

  public setDependantMembersAreaApps(): Promise<void> {
    const membersAPI = this.getMembersApi();
    return membersAPI?.setDependantApps({
      appId: ecomAppDefID,
      dependencies: [myWalletAppDefId, addressesAppDefId],
    });
  }

  public getWishlistPageRef(): Promise<any> {
    return this.getMembersApi().getMembersPageRef({appDefinitionId: ecomAppDefID, appPageId: wishlistPageId});
  }

  public async addLighboxes(applicationId: string) {
    const popupPages = await this.sdk.pages.popupPages.getApplicationPopups(ecomAppDefID);

    for (const lightbox of lightboxes) {
      const pageExists = !!popupPages.find((page) => page.tpaPageId === lightbox.tpaPageId);
      if (pageExists) {
        continue;
      }

      const componentDefinition = {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.TPASectionSkin',
        layout: {
          width: 980,
          height: 693,
          x: 0,
          y: 40,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.tpapps.TPAMultiSection',
        data: {
          type: 'TPAMultiSection',
          applicationId: applicationId.toString(),
          appDefinitionId: ecomAppDefID,
          widgetId: lightbox.widgetId,
        },
      };

      await this.sdk.document.pages.popupPages.add(lightbox.tpaPageId, {
        title: lightbox.title,
        definition: {
          data: {
            managingAppDefId: ecomAppDefID,
            tpaPageId: lightbox.tpaPageId,
            pageBackgrounds: {
              desktop: {
                custom: true,
                ref: {
                  type: 'BackgroundMedia',
                  color: '#A5C8DE',
                  colorOpacity: 0.75,
                  alignType: 'top',
                  fittingType: 'fill',
                  scrollType: 'fixed',
                },
                isPreset: true,
              },
            },
          },
          components: [
            {
              type: 'Container',
              components: [componentDefinition],
              skin: 'wysiwyg.viewer.skins.area.RectangleArea',
              layout: {
                width: 980,
                height: 693,
                x: 0,
                y: 40,
                scale: 1,
                rotationInDegrees: 0,
                fixedPosition: false,
                docked: {
                  hCenter: {px: 0},
                  vCenter: {px: 0},
                },
              },
              componentType: 'wysiwyg.viewer.components.PopupContainer',
              props: {
                type: 'PopupContainerProperties',
              },
            },
          ],
        },
        shouldNavigateToPage: false,
      });
    }
  }
}
